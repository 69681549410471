import React, { useState } from 'react';
import config from '../../config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table, Pagination, Divider } from 'semantic-ui-react';

import { PageSizeSelected } from './PageSizeSelected.jsx';
import TableRow from './TableRow.jsx';
import TableHeader from './TableHeader.jsx';
import Spinner from "../UI/Spinner";
import P2PHistory from "./Reports/P2PHistory";

const CustomTable = props => {
    const [trxModal, setTrxModal] = useState(false);
    const [p2pHistory, setP2PHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { pathname } = props.location;

    const onTransactionClick = (id) => {
        setTrxModal(true);
        setIsLoading(true);
        fetch(`${config.baseURL}/report/p2phistory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "txnKuikId_T1": id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setP2PHistory(data.value);
            setIsLoading(false);
        })
    };

    const closeP2PHandler = () => {
        setTrxModal(false);
    }

    const rowStyleConditioner = (data) => {
        if (pathname === '/transaction-report' && data.txnType === 'Transfer') {
            return { cursor: 'pointer' }
        }
        if (pathname === '/topup-transaction-report' && data.merchantTxState === 'Failed') {
            return { backgroundColor: '#FFABAB' }
        }
    }

    const rows = props.data.map((data, index) => (
        <TableRow
            key={index}
            data={data}
            refreshHandler={props.refreshHandler}
            onClick={pathname === '/transaction-report' && data.txnType === 'Transfer' ? () => onTransactionClick(data.txnKuikId_T1) : null}
            style={rowStyleConditioner(data)}
        />
    ));

    if (!props.data) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <P2PHistory
                isLoading={isLoading}
                p2pHistory={p2pHistory}
                trxModal={trxModal}
                closeP2P={closeP2PHandler}
            />

            <div className={pathname === '/customer-management' ? 'primary-pane' : ''}>
                <div className={pathname === '/customer-management' ? null : 'table-overflow'}>
                    <Table singleLine={pathname === '/customer-management' ? false : true} celled sortable unstackable style={{ borderRadius: 0 }}>
                        <TableHeader
                            data={props.data}
                        />

                        <Table.Body>{props.loading ? <Spinner /> : rows}</Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="36" style={{ borderRadius: 0 }}>
                                    <Pagination
                                        size='mini'
                                        totalPages={props.totalPages}
                                        activePage={props.currentPage}
                                        onPageChange={props.onChangePage}
                                        disabled={props.totalCount <= 0 ? true : false}
                                        style={{ borderRadius: 0, fontSize: 15 }}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
                <Divider />
                <div className="text-right">
                    <PageSizeSelected
                        limit={props.limit}
                        onChangeLimit={props.onChangeLimit}
                    />
                    Total count: {props.totalCount}.
                </div>
            </div>
        </React.Fragment>
    );
};

CustomTable.propTypes = {
    totalCount: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeLimit: PropTypes.func.isRequired,
    limit: PropTypes.string.isRequired,
};

export default withRouter(CustomTable);