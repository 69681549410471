export var reportsList = [
    {
        pathname: '/subscription-report',
        endpoint: '/subscription',
        reportOptions: {
            "subscribedOnStartDate": null,
            "subscribedOnEndDate": null,
            "customerNumber": null,
            "msisdn": null,
            "iban": null,
            "channel": null
        }
    },
    {
        pathname: '/subscription-status-report',
        endpoint: '/subscriptionstatus',
        reportOptions: {
            "modifiedOnStartDate": null,
            "modifiedOnEndDate": null,
            "customerNumber": null,
            "msisdn": null,
            "iban": null
        }
    },
    {
        pathname: '/unsubscription-report',
        endpoint: '/unsubscription',
        reportOptions: {
            "unsubscribedOnStartDate": null,
            "unsubscribedOnEndDate": null,
            "customerNumber": null,
            "msisdn": null,
            "iban": null
        }
    },
    {
        pathname: '/settlement-report',
        endpoint: '/settlement',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "mPayTransactionId": null,
            "rbalTransactionId": null,
            "iban": null
        }
    },
    {
        pathname: '/transaction-report',
        endpoint: '/transaction',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "kuikSubscriptionId_T1": null,
            "kuikSubscriptionId_T2": null,
            "msisdn_T1": null,
            "msisdn_T2": null,
            "iban_T1": null,
            "iban_T2": null,
            "txn_T1": null,
            "txn_T2": null,
            "channel": null,
        }
    },
    {
        pathname: '/subscription-device-report',
        endpoint: '/subscriptiondevice',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "kuikCustomerId": null,
            "msisdn": null,
            "iban": null
        }
    },
    {
        pathname: '/subscription-modification-report',
        endpoint: '/subscriptionmodification',
        reportOptions: {
            "modifiedOnStartDate": null,
            "modifiedOnEndDate": null,
            "kuikCustomerId": null,
            "msisdnNew": null,
            "ibanNew": null
        }
    },
    {
        pathname: '/sms-sent-report',
        endpoint: '/smssent',
        reportOptions: {
            "sentOnStartDate": null,
            "sentOnEndDate": null,
            "phoneNumber": null
        }
    },
    {
        pathname: '/request-money-report',
        endpoint: '/requestmoney',
        reportOptions: {
            "sentOnStartDate": null,
            "sentOnEndDate": null,
            "msisdn": null
        }
    },
    {
        pathname: '/topup-settlement-report',
        endpoint: '/topup_settlement',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null
        }
    },
    {
        pathname: '/topup-transaction-report',
        endpoint: '/topup_transaction',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "mPayTransactionId": null,
            "rbalTransactionId": null,
            "merchant": null,
            "iban": null,
            "msisdn": null,
            "channel": null
        }
    }
]
