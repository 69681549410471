import React, { useState, useEffect } from 'react';
import { Modal, Step } from 'semantic-ui-react';
import Steps from './Steps';

const CustomModal = props => {
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const closeModal = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (!open) {
            setStep(1);
        }
    }, [open]);

    return (
        <Modal className={step === 2 ? 'regular-modal step-2' : 'regular-modal'} onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)} trigger={props.trigger} open={open}>
            <Modal.Header>{props.header}</Modal.Header>
            <Modal.Content>
                <Step.Group ordered>
                    <Step active={step === 1 ? true : false} completed={step > 1 ? true : false}>
                        <Step.Content>
                            <Step.Title>Get IBAN</Step.Title>
                        </Step.Content>
                    </Step>

                    <Step active={step === 2 ? true : false} completed={step > 2 ? true : false}>
                        <Step.Content>
                            <Step.Title>Customer</Step.Title>
                        </Step.Content>
                    </Step>

                    <Step active={step === 3 ? true : false}>
                        <Step.Content>
                            <Step.Title>Create Subscription</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
                <Steps step={step} nextStep={nextStep} prevStep={prevStep} closeModal={closeModal} refreshHandler={props.refreshHandler} />
            </Modal.Content>
        </Modal>
    );
};

export default CustomModal;