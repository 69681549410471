import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Grid } from 'semantic-ui-react';
import CustomModal from './UI/CustomModal';
import Helper from '../helper';
import ExportData from './ExportData';
import HavePermission from './PermissionsService/HavePermission';

class HeaderContent extends Component {
    render() {
        const { pathname } = this.props.location;

        return (
            <Grid>
                <Grid.Column tablet={16} computer={6}>
                    <h2>{pathname === '/customer-management' ? 'Customer Management' : Helper.titleCaseForTabs(pathname.substr(1).split("-").join(' '))}</h2>
                </Grid.Column>
                <Grid.Column tablet={16} computer={10} className="buttons-group-col">
                    <Button.Group size='mini'>
                        <ExportData />
                        {(pathname === '/customer-management') ? (
                            <HavePermission for="customer.management.create.subscription">
                                <CustomModal refreshHandler={this.props.onRefreshHandler} trigger={<Button icon primary labelPosition='left'>New Subscription<Icon name='plus' /></Button>} header='New Subscription' />
                            </HavePermission>) : null}
                    </Button.Group>
                </Grid.Column>
            </Grid>
        );
    }
}

export default withRouter(HeaderContent);