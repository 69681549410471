import React, { Component } from 'react';
import Tabs from "react-draggable-tabs";
import { Redirect, Route } from 'react-router-dom';

import Navbar from '../components/Navbar.jsx';
import List from "../components/Table/List";
import ReportsList from "../components/Table/Reports/ReportsList";
import SidebarFilter from "../components/Filters/SidebarFilter";
import HavePermission from '../components/PermissionsService/HavePermission';

import { connect } from 'react-redux';
import { setFilters } from '../store/actions/filters'

class Layout extends Component {
    constructor(props) {
        super(props);
        this.moveTab = this.moveTab.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.closedTab = this.closedTab.bind(this);
        this.addTab = this.addTab.bind(this);
        this.refreshHandler = this.refreshHandler.bind(this);
        this.state = {
            tabs: [
                {
                    id: 1,
                    content: "Dashboard",
                    active: true,
                    pathname: '/dashboard'
                }
            ],
        };
    }

    componentDidMount() {
        this.props.setFilters(this.props.location.pathname);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.setFilters(this.props.location.pathname);
        }

        if (prevProps.location.state !== this.props.location.state) {
            if (this.props.location.state && this.props.location.state.from === '404') {
                this.setState((state, props) => {
                    let newTabs = [{
                        id: 1,
                        content: "Customer Management",
                        active: true,
                        pathname: '/customer-management'
                    }];

                    return { tabs: newTabs };
                })
            }
        }
    }

    moveTab(dragIndex, hoverIndex) {
        this.setState((state, props) => {
            let newTabs = [...state.tabs]
            newTabs.splice(hoverIndex, 0, newTabs.splice(dragIndex, 1)[0]);

            return { tabs: newTabs };
        });
    }

    selectTab(selectedIndex, selectedID) {
        this.setState((state, props) => {
            const newTabs = state.tabs.map(tab => ({
                ...tab,
                active: tab.id === selectedID
            }));
            newTabs.forEach(item => {
                if (item.id === selectedID) {
                    this.props.history.replace({ pathname: item.pathname })
                }
            })

            return { tabs: newTabs };
        });
    }

    closedTab(removedIndex, removedID) {
        if (this.state.tabs.length <= 1) {
            return;
        } else {
            this.setState((state, props) => {
                let newTabs = [...state.tabs];
                newTabs.splice(removedIndex, 1);

                if (state.tabs[removedIndex].active && newTabs.length !== 0) {
                    // automatically select another tab if needed
                    const newActive = removedIndex === 0 ? 0 : removedIndex - 1;
                    newTabs[newActive].active = true;
                    this.props.history.replace({ pathname: newTabs[newActive].pathname })
                }

                return { tabs: newTabs };
            });
        }
    }

    addTab(e) {
        let content = e.target.textContent;

        this.setState((state, props) => {
            let newTabs = [...state.tabs];

            let exists = false;
            newTabs.forEach(item => {
                item.active = false;
                if (item.content === content && !exists) {
                    item.active = true;
                    exists = true;
                }
            });

            if (!exists) {
                newTabs.push({
                    id: content,
                    content: content,
                    active: true,
                    pathname: content.replace(/\s/g, '-').toLowerCase()
                });
            }

            return { tabs: newTabs };
        });
    }

    refreshHandler() {
        this.refreshList();
    }

    render() {
        const reportContent = (
            <div className="content-container reports-container">
                <div className="content-area">
                    <ReportsList />
                </div>
            </div>
        );

        return (
            <div className="main-container">
                <Navbar addTab={this.addTab} user={this.props.user} />
                <div>
                    <Tabs
                        moveTab={this.moveTab}
                        selectTab={this.selectTab}
                        closeTab={this.closedTab}
                        tabs={this.state.tabs}
                    >
                    </Tabs>

                    <Route path='/dashboard' render={() => { }} />
                    <HavePermission for="customer.management.subscription.list">
                        <Route path='/customer-management' render={() => (
                            <div className="content-container">
                                <SidebarFilter refreshHandler={this.refreshHandler} />
                                <div className="content-area">
                                    <List setRefresh={refresh => this.refreshList = refresh} />
                                </div>
                            </div>
                        )} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.subscription.report">
                        <Route path='/subscription-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.subscription.status.report">
                        <Route path='/subscription-status-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.unsubscription.report">
                        <Route path='/unsubscription-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.settlement.report">
                        <Route path='/settlement-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.transaction.report">
                        <Route path='/transaction-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.subscription.device.report">
                        <Route path='/subscription-device-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.subscription.modification.report">
                        <Route path='/subscription-modification-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.sms.sent.report">
                        <Route path='/sms-sent-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.request.money.report">
                        <Route path='/request-money-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.topUp.settlement.report">
                        <Route path='/topup-settlement-report' render={() => reportContent} />
                    </HavePermission>
                    <HavePermission for="specific.reports.fetch.topUp.transaction.report">
                        <Route path='/topup-transaction-report' render={() => reportContent} />
                    </HavePermission>
                    <Redirect to={{
                        pathname: '/customer-management',
                        state: { from: '404' }
                    }} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps, {
    setFilters
})(Layout);