import React, { useState, useEffect } from 'react';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import { Table, Dropdown, Select, Form, Modal, Button } from 'semantic-ui-react';
import Dialog from '../UI/Dialog';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import HavePermission from '../PermissionsService/HavePermission';

const TableRow = props => {
    const { data } = props;
    const { id, iban, msisdn, name, personalNumber, birthdate, email, address, city, fathersName, customerBankId, mobileNetworkOperatorId, bankBranchId, isSubscriptionLocked, isDeviceBlocked, description, mobileNetworkOperatorName, subscriptionType, category, channel } = data;

    const [editedIban, setIban] = useState(iban);
    const [customerData, setCustomerData] = useState({
        editedID: id,
        editedName: name,
        editedCustomerBankID: customerBankId,
        editedFathersName: fathersName,
        editedMsisdn: msisdn,
        editedPersonalNumber: personalNumber,
        editedBirthdate: birthdate,
        editedAddress: address,
        editedCity: city,
        editedEmail: email,
        editedDescription: description
    });

    const [open, setOpen] = useState(false);
    const [openLock, setOpenLock] = useState(false);
    const [openBlock, setOpenBlock] = useState(false);
    const [openUpgrade, setOpenUpgrade] = useState(false);
    const [openUnsub, setOpenUnsub] = useState(false);
    const [openIbanPopup, setOpenIbanPopup] = useState(false);
    const [openMsisdnPopup, setOpenMsisdnPopup] = useState(false);

    const [lock, setLock] = useState(isSubscriptionLocked);
    const [block, setBlock] = useState(isDeviceBlocked);
    const [reason, setReason] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

    const [mnos, setMNOS] = useState([]);
    const [selectedMNOID, setSelectedMNOID] = useState(mobileNetworkOperatorId);
    const [bankBranch, setBankBranch] = useState([]);
    const [selectedBankBranch, setSelectedBankBranch] = useState(bankBranchId);
    const [unsubReasons, setUnsubReasons] = useState([]);

    const customerTableRows = [
        {
            value: customerBankId
        },
        {
            value: personalNumber
        },
        {
            value: name
        },
        {
            value: fathersName
        },
        {
            value: msisdn
        },
        {
            value: mobileNetworkOperatorName
        },
        {
            value: subscriptionType
        },
        {
            value: category
        },
        {
            value: isSubscriptionLocked ? "Locked" : "Unlocked"
        },
        {
            value: isDeviceBlocked ? "Blocked" : "Unblocked"
        },
        {
            value: channel === 'RON' ? 'RPLUS' : channel
        }
    ];

    const displayLockText = isSubscriptionLocked ? 'Unlock' : 'Lock';
    const displayBlockText = isDeviceBlocked ? 'Unblock' : 'Block';

    useEffect(() => {
        setIban(iban);
        setCustomerData({
            editedID: id,
            editedName: name,
            editedCustomerBankID: customerBankId,
            editedFathersName: fathersName,
            editedMsisdn: msisdn,
            editedPersonalNumber: personalNumber,
            editedBirthdate: birthdate,
            editedAddress: address,
            editedCity: city,
            editedEmail: email,
            editedDescription: description
        });
        setLock(isSubscriptionLocked);
        setBlock(isDeviceBlocked);
    }, [data]);

    const lockHandler = () => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/lock-unlock`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setLoading(false);
            setLock(lock => !lock);
            props.refreshHandler();
            toast.success(lock ? 'Unlocked Successfully!' : 'Locked Successfully!');
            setOpenLock(false);
        });
    };

    const blockHandler = () => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/block-unblock`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setLoading(false);
            setBlock(block => !block);
            props.refreshHandler();
            toast.success(block ? 'Unblocked Successfully!' : 'Blocked Successfully!');
            setOpenBlock(false);
        })
    };

    const resetPinHandler = () => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/reset-pin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                setLoading(false);
                localStorage.setItem('activationcode', data.value.activationCode);
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                window.open('/form/resetpinform.html', "_blank");
                toast.success('PIN Reseted Successfully');
                setOpen(false);
                props.refreshHandler();
            } else {
                setLoading(false);
                toast.error(data.messages[0].text);
                setOpen(false);
            }
        })
    };

    const upgradeHandler = () => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/upgrade`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                setLoading(false);
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', data.value.birthDate);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                window.open('/form/upgradeform.html', "_blank");
                toast.success('Customer Upgraded Successfully');
                setOpenUpgrade(false);
                props.refreshHandler();
            } else {
                setLoading(false);
                toast.error(data.messages[0].text);
                setOpenUpgrade(false);
            }
        })
    };

    const getUnsubscriptionReasons = () => {
        fetch(`${config.baseURL}/admin/unsubscriptionreason/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setUnsubReasons(data.value.data);
        })
    };

    const unsubscribeHandler = () => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/unsubscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id,
                reasonCode: reason
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                toast.success('Customer Unsubscribed Successfully');
                setOpenUnsub(false);
                props.refreshHandler();
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(data.messages[0].text);
            }
        })
    };

    const syncIbanHandler = () => {
        setError(null);
        setConfirmed(false);
        setLoading(true);
        fetch(`${config.baseURL}/customers/sync-iban`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                iban: editedIban,
                id: customerData.editedID,
                name: customerData.editedName,
                customerBankId: customerData.editedCustomerBankID,
                fathersName: customerData.editedFathersName,
                msisdn: customerData.editedMsisdn,
                personalNumber: customerData.editedPersonalNumber,
                birthdate: customerData.editedBirthdate,
                address: customerData.editedAddress,
                city: customerData.editedCity,
                email: customerData.editedEmail
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                setLoading(false);
                setConfirmed(true);
                setCustomerData({
                    editedID: data.value.id,
                    editedName: data.value.name,
                    editedCustomerBankID: data.value.customerBankId,
                    editedFathersName: data.value.fathersName,
                    editedMsisdn: data.value.msisdn,
                    editedPersonalNumber: data.value.personalNumber,
                    editedBirthdate: data.value.birthdate,
                    editedAddress: data.value.address,
                    editedCity: data.value.city,
                    editedEmail: data.value.email
                })
            } else {
                setError(data.messages[0].text);
                setLoading(false);
            }
        })
    };

    const editIbanHandler = (userData) => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/change-iban`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: id,
                iban: userData.iban,
                fatherName: userData.fatherName,
                birthDate: userData.birthDate,
                address: userData.address,
                city: userData.city,
                email: userData.email,
                bankBranchId: selectedBankBranch,
                mobileNetworkOperatorId: selectedMNOID,
                accountDescription: userData.description
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', data.value.birthDate);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('activationcode', data.value.activationCode);
                setOpenIbanPopup(false);
                setLoading(false);
                window.location.reload();
                props.refreshHandler();
                window.open('/form/modificationform.html', "_blank");
                toast.success('IBAN Changed Successfully!');
            } else {
                toast.error(data.messages[0].text);
                setLoading(false);
            }
        })
    };

    const editMsisdnHandler = (userData) => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/change-msisdn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id,
                msisdn: userData.msisdn,
                fatherName: userData.fatherName,
                birthDate: userData.birthDate,
                address: userData.address,
                city: userData.city,
                email: userData.email,
                mobileOperatorId: selectedMNOID,
                bankBranchId: selectedBankBranch,
                accountDescription: userData.description
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', data.value.birthDate);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('activationcode', data.value.activationCode);
                setOpenMsisdnPopup(false);
                setLoading(false);
                window.location.reload();
                props.refreshHandler();
                window.open('/form/modificationform.html', "_blank");
                toast.success('MSISDN Changed Successfully!');
            } else {
                toast.error(data.messages[0].text);
                setLoading(false);
            }
        })
    };

    const getMNOList = () => {
        fetch(`${config.baseURL}/admin/mobilenetworkoperator/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setMNOS(data.value.data);
        })
    };

    const getBankBranchList = () => {
        fetch(`${config.baseURL}/admin/bankbranch/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setBankBranch(data.value.data);
        })
    };

    const onSelectMNOHandler = (e, data) => {
        mnos.map(mno => {
            if (mno.name === data.value) {
                setSelectedMNOID(mno.id);
            }
        })
    }

    const onSelectBranchHandler = (e, data) => {
        bankBranch.map(branch => {
            if (branch.displayName === data.value) {
                setSelectedBankBranch(branch.id);
            }
        })
    }

    const cellStyleConditioner = (index) => {
        if ((pathname === '/subscription-status-report' && index === 17) || (pathname === '/transaction-report' && index === 31)) {
            return {
                display: 'none'
            };
        }
    };

    const { pathname } = props.location;
    let content;

    if (pathname === '/customer-management') {
        content = customerTableRows.map((item, index) => {
            return (
                <Table.Cell key={index}>{item.value}</Table.Cell>
            );
        });
    } else {
        delete props.data.sentTime;
        delete props.data.userIp;

        if (pathname === '/subscription-device-report') {
            delete props.data.createdOnUtc;
            delete props.data.iban;
        }

        if (pathname === '/subscription-status-report' || pathname === '/subscription-report' || pathname === '/settlement-report') {
            delete props.data.category;
        }

        if (pathname === '/transaction-report') {
            delete props.data.initiatedOn;
        }

        if(pathname === '/sms-sent-report') {
            delete props.data.sourcePhone;
        }

        content = Object.values(props.data).map((item, index) => {
            return (
                <Table.Cell
                    key={index}
                    style={cellStyleConditioner(index)}
                >
                    {item === 'fullyFledged' ? 'Fully-Fledged' : item === 'limited' ? 'Limited' : item === 'RON' ? 'RPLUS' : item}
                </Table.Cell>
            );
        });
    };

    return (
        <React.Fragment>
            <Table.Row onClick={props.onClick} style={props.style}>
                {content}
                {props.location.pathname === '/customer-management' ? (
                    <Table.Cell>
                        <Dropdown pointing icon='ellipsis vertical'>
                            <Dropdown.Menu style={dropdownStyle}>
                                {channel !== "RPLUS" ? (
                                    <React.Fragment>
                                        <HavePermission for="customer.management.edit.iban">
                                            <Dropdown.Item style={itemStyle} onClick={() => {
                                                setOpenIbanPopup(true);
                                                getMNOList();
                                                getBankBranchList();
                                            }}>Edit IBAN</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.edit.msisdn">
                                            <Dropdown.Item style={itemStyle} onClick={() => {
                                                setOpenMsisdnPopup(true);
                                                getMNOList();
                                                getBankBranchList();
                                            }}>Edit MSISDN</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.upgrade.subscription">
                                            <Dropdown.Item style={itemStyle} onClick={() => setOpenUpgrade(true)}>Upgrade</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.reset.customer.pin">
                                            <Dropdown.Item style={itemStyle} onClick={() => setOpen(true)}>Reset PIN</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.lock/unlock.subscription">
                                            <Dropdown.Item style={itemStyle} onClick={() => setOpenLock(true)}>{displayLockText}</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.block/unblock.subscription">
                                            <Dropdown.Item style={itemStyle} onClick={() => setOpenBlock(true)}>{displayBlockText}</Dropdown.Item>
                                        </HavePermission>
                                    </React.Fragment>
                                ) : null}
                                <HavePermission for="customer.management.unsubscribe">
                                    <Dropdown.Item style={itemStyle} onClick={() => {
                                        setOpenUnsub(true);
                                        getUnsubscriptionReasons();
                                    }}>Unsubscribe</Dropdown.Item>
                                </HavePermission>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Table.Cell>
                ) : null}
            </Table.Row>

            <Dialog
                open={open}
                onCancel={() => { setOpen(false) }}
                onConfirm={resetPinHandler}
                title={'Reset PIN'}
                confirmText={'Reset Pin'}
                loading={loading}
            ><p>Are you sure to Reset PIN for selected customer?</p></Dialog>

            <Dialog
                open={openLock}
                onCancel={() => { setOpenLock(false) }}
                onConfirm={lockHandler}
                title={displayLockText + ' Customer'}
                confirmText={displayLockText}
                loading={loading}
            ><p>Are you sure to {displayLockText} the selected customer?</p></Dialog>

            <Dialog
                open={openBlock}
                onCancel={() => { setOpenBlock(false) }}
                onConfirm={blockHandler}
                title={displayBlockText + ' Customer'}
                confirmText={displayBlockText}
                loading={loading}
            ><p>Are you sure to {displayBlockText} the selected customer?</p></Dialog>

            <Dialog
                open={openUpgrade}
                onCancel={() => { setOpenUpgrade(false) }}
                onConfirm={upgradeHandler}
                title={'Upgrade Customer'}
                confirmText={'Upgrade'}
                loading={loading}
            ><p>Are you sure to Upgrade for selected customer?</p></Dialog>

            <Modal
                open={openUnsub}
                className={'regular-modal unsub-modal'}
                size={'small'}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{ reason: '' }}
                    onSubmit={values => {
                        unsubscribeHandler();
                    }}
                    validationSchema={Yup.object().shape({
                        reason: Yup.string()
                            .required('This field is required.')
                            .trim()
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit, isValid }) => (
                        <React.Fragment>
                            <Modal.Header>Unsubscribe</Modal.Header>
                            <Modal.Content>
                                <div>
                                    <p>Please select unsubscribe reason for the list.</p>
                                    <Form.Field>
                                        <select
                                            name='reason'
                                            value={values.reason}
                                            onChange={(e) => {
                                                handleChange(e);
                                                unsubReasons.map(reason => {
                                                    if (reason.reason === e.target.value) {
                                                        setReason(reason.indexId)
                                                    }
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            style={{ borderRadius: 0, minHeight: 50, paddingTop: 5, paddingLeft: 10, marginTop: 10, width: '100%' }}>
                                            <option value=''>Unsubscribe Reason</option>
                                            {unsubReasons.map(el => {
                                                return <option value={el.reason} key={el.indexId}>{el.reason}</option>
                                            })}
                                        </select>
                                        {errors.reason && touched.reason ? (
                                            <span style={{ fontSize: 13, color: '#078ec9' }}>{errors.reason}</span>
                                        ) : null}
                                    </Form.Field>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => { setOpenUnsub(false) }}>
                                    Cancel
                                </Button>
                                <Button disabled={!reason || errors.reason} loading={loading} primary onClick={handleSubmit}>
                                    Unsubscribe
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>

            <Modal
                className={'large-modal'}
                size={'large'}
                open={openIbanPopup}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{ iban: editedIban, fatherName: customerData.editedFathersName, birthDate: customerData.editedBirthdate ? moment(customerData.editedBirthdate).format('YYYY-MM-DD') : customerData.editedBirthdate, email: customerData.editedEmail, address: customerData.editedAddress, city: customerData.editedCity, description: customerData.editedDescription }}
                    onSubmit={(values) => {
                        editIbanHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Invalid email')
                            .required('This field is required.')
                            .trim(),
                        birthDate: Yup.string()
                            .required('This field is required.')
                            .trim()
                            .nullable(),
                        address: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        city: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        fatherName: Yup.string()
                            .required('This field is required.')
                            .trim(),
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit, isValid }) => (
                        <React.Fragment>
                            <Modal.Header>Edit IBAN</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Iban" type='text' name='iban' placeholder='Iban' value={editedIban} onChange={(e) => {
                                                setIban(e.target.value);
                                            }} onBlur={handleBlur} />
                                            {error ? <span className='formik-error-msg'>{error}</span> : null}
                                        </Form.Field>
                                        <Form.Field style={{ paddingTop: 22 }}>
                                            <Button loading={loading} primary onClick={syncIbanHandler} style={{ width: '100%', height: 35 }}>
                                                SYNC
                                       </Button>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Father Name" type='text' name='fatherName' placeholder='Father Name' value={values.fatherName} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.fatherName && touched.fatherName ? (
                                                <span className='formik-error-msg'>{errors.fatherName}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Input fluid label="Full Name" type='text' name='fullName' placeholder='Full Name' value={customerData.editedName} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Personal Number" type='text' name='personalNumber' placeholder='Personal Number' value={customerData.editedPersonalNumber} readOnly />
                                        <Form.Input fluid label="Customer Bank ID" type='text' name='customerBankId' placeholder='Customer Bank ID' value={customerData.editedCustomerBankID} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Customer Phone Number" type='text' name='phoneNumber' placeholder='Phone Number' value={customerData.editedMsisdn} readOnly />
                                        <Form.Field>
                                            <Form.Input fluid label="Customer Birth Date" type='date' name='birthDate' placeholder='Customer Birth Date' value={values.birthDate} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.birthDate && touched.birthDate ? (
                                                <span className='formik-error-msg'>{errors.birthDate}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.address && touched.address ? (
                                                <span className='formik-error-msg'>{errors.address}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="City" type='text' name='city' placeholder='City' value={values.city} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.city && touched.city ? (
                                                <span className='formik-error-msg'>{errors.city}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.email && touched.email ? (
                                                <span className='formik-error-msg'>{errors.email}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Mobile Operator</label>
                                            <Select placeholder={mnos.map(mno => {
                                                if (selectedMNOID === mno.id) {
                                                    return mno.name;
                                                }
                                            })} onChange={onSelectMNOHandler} options={mnos.map(mno => {
                                                return { key: mno.id, value: mno.name, text: mno.name };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Bank Branch</label>
                                            <Select placeholder={bankBranch.map(branch => {
                                                if (selectedBankBranch === branch.id) {
                                                    return branch.displayName;
                                                }
                                            })} onChange={onSelectBranchHandler} options={bankBranch.map(branch => {
                                                return { key: branch.id, value: branch.displayName, text: branch.displayName };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Account Description" type='text' name='description' placeholder='Account Description' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => { setOpenIbanPopup(false) }}>
                                    Cancel
                       </Button>
                                <Button disabled={!confirmed} loading={loading} primary onClick={handleSubmit}>
                                    Change Iban
                       </Button>
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>


            <Modal
                className={'large-modal msisdn'}
                size={'large'}
                open={openMsisdnPopup}
            >
                <Formik
                    initialValues={{ fatherName: fathersName, birthDate: birthdate ? moment(birthdate).format('YYYY-MM-DD') : birthdate, email: email, address: address, city: city, msisdn: msisdn, description: description }}
                    onSubmit={values => {
                        editMsisdnHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Invalid email')
                            .required('This field is required.')
                            .trim(),
                        birthDate: Yup.string()
                            .required('This field is required.')
                            .trim()
                            .nullable(),
                        msisdn: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        address: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        city: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        fatherName: Yup.string()
                            .required('This field is required.')
                            .trim(),
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit, isValid }) => (
                        <React.Fragment>
                            <Modal.Header>Edit Msisdn</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Iban" type='text' name='iban' placeholder='Iban' value={iban} readOnly />
                                        <Form.Field>
                                            <Form.Input fluid label="Father Name" type='text' name='fatherName' placeholder='Father Name' value={values.fatherName} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.fatherName && touched.fatherName ? (
                                                <span className='formik-error-msg'>{errors.fatherName}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Full Name" type='text' name='fullName' placeholder='Full Name' value={name} readOnly />
                                        <Form.Input fluid label="Personal Number" type='text' name='personalNumber' placeholder='Personal Number' value={personalNumber} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Customer Bank ID" type='text' name='customerBankId' placeholder='Customer Bank ID' value={customerBankId} readOnly />
                                        <Form.Field>
                                            <Form.Input fluid label="Customer Birth Date" type='date' name='birthDate' placeholder='Customer Birth Date' value={values.birthDate} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.birthDate && touched.birthDate ? (
                                                <span className='formik-error-msg'>{errors.birthDate}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.email && touched.email ? (
                                                <span className='formik-error-msg'>{errors.email}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.address && touched.address ? (
                                                <span className='formik-error-msg'>{errors.address}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="City" type='text' name='city' placeholder='City' value={values.city} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.city && touched.city ? (
                                                <span className='formik-error-msg'>{errors.city}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Customer Phone Number" type='text' name='msisdn' placeholder='Phone Number' value={values.msisdn} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.msisdn && touched.msisdn ? (
                                                <span className='formik-error-msg'>{errors.msisdn}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Mobile Operator</label>
                                            <Select placeholder={mnos.map(mno => {
                                                if (selectedMNOID === mno.id) {
                                                    return mno.name;
                                                }
                                            })} onChange={onSelectMNOHandler} options={mnos.map(mno => {
                                                return { key: mno.id, value: mno.name, text: mno.name };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bank Branch</label>
                                            <Select placeholder={bankBranch.map(branch => {
                                                if (selectedBankBranch === branch.id) {
                                                    return branch.displayName;
                                                }
                                            })} onChange={onSelectBranchHandler} options={bankBranch.map(branch => {
                                                return { key: branch.id, value: branch.displayName, text: branch.displayName };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Account Description" type='text' name='description' placeholder='Account Description' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                                    </Form.Group>
                                </Form>

                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => { setOpenMsisdnPopup(false) }}>
                                    Cancel
                                </Button>
                                <Button type='button' loading={loading} primary onClick={handleSubmit}>
                                    Change Msisdn
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>

            <ToastContainer />
        </React.Fragment>
    );
}

const dropdownStyle = {
    marginLeft: '-58px'
}

const itemStyle = {
    fontSize: '.7rem'
}

TableRow.propTypes = {
    data: PropTypes.object.isRequired,
};

export default withRouter(TableRow);