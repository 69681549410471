import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import TableButtons from '../Table/TableButtons';
import { Form, Dropdown, Ref } from 'semantic-ui-react';
import Input from '../UI/Input';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { connect } from 'react-redux';

const options = [
    { key: 0, value: 'ron', text: 'RPLUS' }
];
class TableFilters extends Component {
    constructor(props) {
        super(props);
        this.dropdownItem = React.createRef();
        this.clearChannelValue = this.clearChannelValue.bind(this);
    }

    clearChannelValue() {
        var element = this.dropdownItem.current.querySelector('[aria-selected="true"]');
        if (element && this.dropdownItem.current.querySelector(".clear")) {
            this.dropdownItem.current.querySelector(".clear").click();
        }
    }

    render() {
        let content;
        const { pathname } = this.props.location;
        if (pathname !== '/transaction-report') {
            content = (
                <Form.Group widths='equal'>
                    {this.props.filterInputs.map((item, index) => {
                        if (item.model === 'select') {
                            return (
                                <Form.Field key={index}>
                                    <label>Channels</label>
                                    <Ref innerRef={this.dropdownItem}>
                                        {
                                            this.props.user.includes('permissions.customer.management.isRbksUser') ?
                                                <Dropdown
                                                    placeholder="Channels"
                                                    fluid
                                                    value="ron"
                                                    selection
                                                    options={options}
                                                    onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                    style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                    clearable
                                                /> : <Dropdown
                                                    placeholder="Channels"
                                                    fluid
                                                    selection
                                                    options={item.channels.map(channel => {
                                                        return { key: channel.key, value: channel.value, text: channel.text }
                                                    })}
                                                    onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                    style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                    clearable
                                                />
                                        }
                                    </Ref>
                                </Form.Field>
                            );
                        } else {
                            if ((pathname === '/topup-transaction-report' || pathname === '/topup-settlement-report') && item.type === 'datetime-local') {
                                return (
                                    <Form.Field key={index}>
                                        <label>{item.label}</label>
                                        <Datetime value={item.value.replace('T', ' ')} dateFormat='YYYY-MM-DD' timeFormat='HH:mm:ss' onClose={this.props.onCloseHandler} onChange={(date) => {
                                            this.props.onChangeHandler(date, index)
                                        }}
                                            renderInput={(props) => {
                                                return <input {...props} value={item.value.replace('T', ' ')} onChange={(e) => this.props.onManualDateChange(e, index)} />
                                            }} />
                                    </Form.Field>
                                )
                            } else {
                                return (
                                    <Form.Field key={index}>
                                        {
                                            this.props.user.includes('permissions.customer.management.isRbksUser') && item.label === 'Rbal Transaction Id' ?
                                                <Input label='Rbks Transaction Id' type='text' name='Rbks Transaction Id' placeholder='Rbks Transaction Id'
                                                    value={item.value}
                                                    onChange={(e) => { this.props.onChangeHandler(e, index) }} /> :
                                                <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={
                                                    item.label === 'Start Date' ? item.value.replace('T00:00:00', '') : item.label === 'End Date' ? item.value.replace('T23:59:59', '') : item.value
                                                } onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                        }
                                    </Form.Field>
                                );
                            }
                        }
                    })}
                </Form.Group>
            );
        } else {
            content = (
                <React.Fragment>
                    <Form.Group>
                        {this.props.filterInputs.map((item, index) => {

                            if (index == 0 || index == 1) {
                                return (
                                    <Form.Field key={index}>
                                        <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={
                                            item.label === 'Start Date' ? item.value.replace('T00:00:00', '') : item.label === 'End Date' ? item.value.replace('T23:59:59', '') : item.value
                                        } onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                    </Form.Field>
                                );
                            }
                            if (index > 1 && index <= 5) {
                                return (
                                    <Form.Field key={index} style={{ flex: 1 }}>
                                        <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={item.value} onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                    </Form.Field>
                                );
                            }
                        })}
                    </Form.Group>

                    <Form.Group>
                        {this.props.filterInputs.map((item, index) => {
                            if (index > 5) {
                                if (item.model === 'select') {
                                    return (
                                        <Form.Field style={{ flex: 1 }} key={index}>
                                            <label>Channels</label>
                                            <Ref innerRef={this.dropdownItem}>
                                                {
                                                    this.props.user.includes('permissions.customer.management.isRbksUser') ?
                                                        <Dropdown
                                                            placeholder="Channels"
                                                            fluid
                                                            value="ron"
                                                            selection
                                                            options={options}
                                                            onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                            style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                            clearable
                                                        /> : <Dropdown
                                                            placeholder="Channels"
                                                            fluid
                                                            selection
                                                            options={item.channels.map(channel => {
                                                                return { key: channel.key, value: channel.value, text: channel.text }
                                                            })}
                                                            onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                            style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                            clearable
                                                        />
                                                }
                                            </Ref>
                                        </Form.Field>
                                    );
                                } else {
                                    return (
                                        <Form.Field key={index} style={{ flex: 1 }}>
                                            <Input label={item.label} type={item.type} name={item.label}
                                                placeholder={item.placeholder ? item.placeholder : item.label}
                                                value={item.value} onChange={(e) => {
                                                    this.props.onChangeHandler(e, index);

                                                }} />
                                        </Form.Field>
                                    );
                                }
                            }
                        })}
                    </Form.Group>
                </React.Fragment>
            );
        }

        return (
            <Fragment>
                <Form size='mini'>
                    {content}
                </Form>
                <TableButtons
                    pathname={pathname}
                    clearChannelValue={this.clearChannelValue}
                    refreshHandler={this.props.refreshHandler}
                    resetFilters={this.props.resetFilters}
                    loading={this.props.loading}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user['claim.permission']
})

export default connect(mapStateToProps, null)(withRouter(TableFilters));