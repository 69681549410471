import React from 'react';
import config from '../../../config';
import { withRouter } from 'react-router-dom';
import Table from '../Table';
import Header from '../../Header';
import { reportsList } from './reports-list';
import { connect } from 'react-redux';
import { setFilters } from '../../../store/actions/filters';
import moment from 'moment';

class ReportsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            limit: 10,
            totalCount: 0,
            totalPages: 0,
            loading: false,
            filters: []
        };
    }

    componentDidMount() {
        this.setState({ filters: this.props.filters.filters });
        this.loadData({});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filters !== this.state.filters) {
          this.setState({ filters: this.props.filters.filters });
        }
    }

    onChangeLimit = (event, data) => {
        if (data.value !== this.state.limit) {
            this.setState({ limit: parseInt(data.value) })
            this.setState({ page: 1 });
            this.loadData({ limit: parseInt(data.value), page: this.state.page });
        }
    };

    onChangePage = (event, data) => {
        const { activePage } = data;
        if (activePage !== this.state.page) {
            this.setState({ page: activePage });
            this.loadData({ page: activePage, limit: this.state.limit });
        }
    };

    getFilterValue = (position) => {
        return this.state.filters.length > 0 ? this.state.filters[position].value !== '' ? this.state.filters[position].value : null : null
    }

    getFilterValueForChannels = (position, channel) => {
        return this.state.filters.length > 0 && this.state.filters[position].channels !== null ?
            this.state.filters[position].channels[channel].isClicked === true ? this.state.filters[position].channels[channel].key :
                this.state.filters[position].channels[channel - 1].isClicked === true ? this.state.filters[position].channels[channel - 1].key : null : null
    }

    loadData = params => {
        const baseUrl = `${config.baseURL}/report`;
        let url;
        const baseOptions = {
            "pageIndex": params.page - 1,
            "pageSize": params.limit
        }

        let otherOptions = {}

        let { pathname } = this.props.location;

        reportsList.map(report => {
            if (report.pathname === pathname) {
                otherOptions = report.reportOptions;
                url = baseUrl + report.endpoint;
                Object.keys(report.reportOptions).map((opt, index) => {
                    if (opt !== "channel" && opt !== "exportAll") {
                        report.reportOptions[opt] = this.getFilterValue(index);

                        if(report.pathname === '/transaction-report' || report.pathname === '/settlement-report') {
                            if(report.reportOptions['createdOnStartDate'] === null || report.reportOptions['createdOnEndDate'] === null) {
                                report.reportOptions['createdOnStartDate'] = moment(new Date()).format('YYYY-MM-DDT00:00:00');
                                report.reportOptions['createdOnEndDate'] = moment(new Date()).format('YYYY-MM-DDT23:59:59');
                            }
                        }
                    } else if (opt === "channel" && opt !== "exportAll") {
                        report.reportOptions[opt] = this.getFilterValueForChannels(index, 2);
                    }
                });
            }
        })

        let options = Object.assign({}, otherOptions, baseOptions);

        this.setState({ loading: true });
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(options)
        }).then(response => {
            return response.json();
        }).then(data => {
            if(data.success) {
                this.setState({ data: data.value.data });
                this.setState({ totalCount: data.value.totalCount });
                this.setState({ totalPages: data.value.totalPages });
            } else {
                console.log(`Failed to load data`);
            }
            this.setState({ loading: false });
        });
    };

    onRefreshHandler = () => {
        this.loadData({ page: 1 });
    }

    onResetFilters = () => {
        this.props.setFilters(this.props.location.pathname);
        this.setState({ filters: this.props.filters.filters }, this.loadData({ page: 1 }));
        this.setState({ page: 1 });
    }

    render() {
        return (
            <div>
                <Header
                    filters={this.state.filters}
                    loading={this.state.loading}
                    onRefreshHandler={this.onRefreshHandler}
                    onResetFilters={this.onResetFilters}
                />

                <Table
                    onChangePage={this.onChangePage}
                    onChangeLimit={this.onChangeLimit}
                    data={this.state.data}
                    filters={this.state.filters}
                    totalCount={this.state.totalCount}
                    totalPages={this.state.totalPages}
                    currentPage={this.state.page}
                    limit={this.state.limit.toString()}
                    loading={this.state.loading}
                    refreshHandler={this.onRefreshHandler}
                    onResetFilters={this.onResetFilters}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
})

export default connect(mapStateToProps, {setFilters})(withRouter(ReportsList));