import React from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Modal, Button } from 'semantic-ui-react';

import Spinner from "../../UI/Spinner";
import 'react-splitter-layout/lib/index.css';

const P2PHistory = props => {
    let { p2pHistory, isLoading, trxModal } = props;

    const headers = [
        {
            title: 'Txn ID'
        },
        {
            title: 'Type'
        },
        {
            title: 'Message'
        },
        {
            title: 'Additional Details'
        },
        {
            title: 'Event Date Time'
        }
    ];

    let content;
    let reOrderedItem;

    if (!isLoading) {
        content = (
            <React.Fragment>
                <h4>#1 Transaction Data:</h4>
                <p>{p2pHistory.descriptionT1 ? `${p2pHistory.descriptionT1}. ` : null}{p2pHistory.comment ? `API Description: ${p2pHistory.comment}` : null}</p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {headers.map((item, index) => {
                                return (
                                    <Table.HeaderCell key={index}>{item.title}</Table.HeaderCell>
                                );
                            })}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {p2pHistory.transferEventsT1 ? p2pHistory.transferEventsT1.map((item, index) => {
                            reOrderedItem = {
                                transferId: item.transferId,
                                type: item.type,
                                message: item.message,
                                details: item.details,
                                createdOnUtc: item.createdOnUtc
                            }
                            return (
                                <Table.Row key={index}>
                                    {Object.values(reOrderedItem).map((el, i) => {
                                        return (
                                            <Table.Cell style={{ padding: '20px 10px' }}>{el}</Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            );
                        }) : null}
                    </Table.Body>
                </Table>

                <h4>#2 Transaction Data:</h4>
                <p>{p2pHistory.descriptionT2 ? `${p2pHistory.descriptionT2}. ` : null}{p2pHistory.comment ? `API Description: ${p2pHistory.comment}` : null}</p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {headers.map((item, index) => {
                                return (
                                    <Table.HeaderCell key={index}>{item.title}</Table.HeaderCell>
                                );
                            })}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {p2pHistory.transferEventsT2 ? p2pHistory.transferEventsT2.map((item, index) => {
                            reOrderedItem = {
                                transferId: item.transferId,
                                type: item.type,
                                message: item.message,
                                details: item.details,
                                createdOnUtc: item.createdOnUtc
                            }
                            return (
                                <Table.Row key={index}>
                                    {Object.values(reOrderedItem).map((el, i) => {
                                        return (
                                            <Table.Cell style={{ padding: '20px 10px' }}>{el}</Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            );
                        }) : null}
                    </Table.Body>
                </Table>
            </React.Fragment>
        )
    } else {
        content = (
            <Spinner />
        )
    }

    return (
        <Modal
            className={'p2p-modal'}
            size={'large'}
            open={trxModal}
        >
            <Modal.Header>REVIEW P2P HISTORY</Modal.Header>
            <Modal.Content>
                {content}
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={props.closeP2P}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default withRouter(P2PHistory);