import React from 'react';
import { Button } from 'semantic-ui-react';
import FilterItem from './FilterItem';
import Checkbox from '../UI/Checkbox';
import { connect } from 'react-redux';
import HavePermission from '../PermissionsService/HavePermission';
class SidebarFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _filters: [],
      checkedItems: new Map()
    }
    this.checkboxHandler = this.checkboxHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.checkboxFilters !== this.props.checkboxFilters) {
      this.setState({ _filters: this.props.checkboxFilters });
    }
  }

  checkboxHandler(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  }

  handleChange(value, key, id) {
    this.state._filters.map(_filter => {
      if (_filter.sectionId === id) {
        _filter.data.map(el => {
          el.isChecked = false;
          this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(el.name, false) }));
        });
        _filter.data[key].isChecked = value;
        _filter.hasClear = false;

        _filter.data.map(filterData => {
          if (filterData.isChecked) {
            _filter.hasClear = true;
          }
        });
      }
    });
  }

  onClearHandler(id) {
    let newFilters = [...this.state._filters];
    newFilters.map(_filter => {
      if (_filter.sectionId === id) {
        _filter.hasClear = false;
        _filter.data.map(el => {
          el.isChecked = false;
        })
        _filter.data.map(d => {
          this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(d.name, false) }));
        })
      }
    });

    this.setState({ _filters: newFilters });
    this.props.refreshHandler();
  }

  clearAllFilters = () => {
    const newFilters = [...this.state._filters];
    newFilters.map(_filter => {
      _filter.hasClear = false;
      _filter.data.map(el => {
        el.isChecked = false;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(el.name, false) }));
      })
    });
    this.setState({ _filters: newFilters });
    this.props.refreshHandler();
  }

  render() {
    return (
      <nav className="sidenav">
        <div className="container" style={{ paddingLeft: 25 }}>
          <h3 style={{ marginTop: 20 }}>Filters</h3>
          <Button size='mini' onClick={this.clearAllFilters} style={{ marginTop: 25 }}>Reset Filters</Button>
          {this.state._filters.map((_filter, index) => (
            <HavePermission for="customer.management.isRbksUser" title={_filter.title}>
              <FilterItem key={_filter.sectionId} title={_filter.title} id={_filter.sectionId} hasClear={_filter.hasClear} clearHandler={() => {
                this.onClearHandler(_filter.sectionId);
              }}>
                {_filter.data.map((filterData, index) => (
                  <Checkbox
                    key={filterData.name}
                    name={filterData.name}
                    label={filterData.label === 'RON' ? 'RPLUS' : filterData.label}
                    onChange={(e) => {
                      this.handleChange(!this.state.checkedItems.get(filterData.name), index, _filter.sectionId);
                      this.checkboxHandler(e);
                      this.props.refreshHandler();
                    }}
                    checked={this.state.checkedItems.get(filterData.name)}
                  />
                ))}
              </FilterItem>
            </HavePermission>
          ))}
        </div>
      </nav>
    );
  };
}

const mapStateToProps = state => ({
  checkboxFilters: state.filters.checkboxFilters,
  user: state.auth.user['claim.permission']
})

export default connect(mapStateToProps, null)(SidebarFilter);