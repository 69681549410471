import moment from 'moment';

let startDate = moment(new Date()).format('YYYY-MM-DDT00:00:00');
let endDate = moment(new Date()).format('YYYY-MM-DDT23:59:59');

export var filterInputsList = [
    {
        pathname: '/customer-management',
        filters: [
            {
                label: "Customer Bank ID",
                type: "text",
                value: ''
            },
            {
                label: "Customer Name",
                placeholder: "Search by Customer Name",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN",
                placeholder: "Search by MSISDN",
                type: "text",
                value: ''
            },
            {
                label: "Personal Number",
                placeholder: "Search by Personal Number",
                type: "text",
                value: ''
            },
            {
                label: "IBAN",
                placeholder: "Search by IBAN",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/subscription-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "Customer Number",
                placeholder: "Search by Customer Number",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN",
                type: "text",
                value: ''
            },
            {
                label: "IBAN",
                type: "text",
                value: ''
            },
            {
                label: 'Channels',
                model: 'select',
                channels: [
                    {
                        key: null,
                        value: 'all',
                        text: 'All Channels',
                        isClicked: false
                    },
                    {
                        key: 0,
                        value: 'kuik',
                        text: 'KUIK',
                        isClicked: false
                    },
                    {
                        key: 1,
                        value: 'ron',
                        text: 'RPLUS',
                        isClicked: false
                    }
                ]
            }
        ]
    },
    {
        pathname: '/subscription-status-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "Customer Number",
                placeholder: "Search by Customer Number",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN",
                type: "text",
                value: ''
            },
            {
                label: "IBAN",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/unsubscription-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "Customer Number",
                placeholder: "Search by Customer Number",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN",
                type: "text",
                value: ''
            },
            {
                label: "IBAN",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/settlement-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "MPAY Transaction Id",
                type: "text",
                value: ''
            },
            {
                label: "Rbal Transaction Id",
                type: "text",
                value: ''
            },
            {
                label: "IBAN",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/transaction-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "KUIK Subscription ID (T1)",
                type: "text",
                value: ''
            },
            {
                label: "KUIK Subscription ID (T2)",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN (T1)",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN (T2)",
                type: "text",
                value: ''
            },
            {
                label: "IBAN (T1)",
                type: "text",
                value: ''
            },
            {
                label: "IBAN (T2)",
                type: "text",
                value: ''
            },
            {
                label: "Txn KUIK ID (T1)",
                type: "text",
                value: ''
            },
            {
                label: "Txn KUIK ID (T2)",
                type: "text",
                value: ''
            },
            {
                label: 'Channels',
                model: 'select',
                channels: [
                    {
                        key: null,
                        value: 'all',
                        text: 'All Channels',
                        isClicked: false
                    },
                    {
                        key: 0,
                        value: 'kuik',
                        text: 'KUIK',
                        isClicked: false
                    },
                    {
                        key: 1,
                        value: 'ron',
                        text: 'RPLUS',
                        isClicked: false
                    }
                ]
            }
        ]
    },
    {
        pathname: '/subscription-device-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "KUIK Customer ID",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN",
                type: "text",
                value: ''
            },
            {
                label: "IBAN",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/subscription-modification-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "KUIK Customer ID",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN New",
                type: "text",
                value: ''
            },
            {
                label: "IBAN New",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/sms-sent-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "Phone Number",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/request-money-report',
        filters: [
            {
                label: "Start Date",
                type: "date",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'date',
                value: endDate
            },
            {
                label: "MSISDN",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/topup-transaction-report',
        filters: [
            {
                label: "Start Date",
                type: "datetime-local",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'datetime-local',
                value: endDate
            },
            {
                label: "MPAY Transaction ID",
                type: "text",
                value: ''
            },
            {
                label: "RBAL Transaction ID",
                type: "text",
                value: ''
            },
            {
                label: "Merchant",
                type: "text",
                value: ''
            },
            {
                label: "IBAN",
                type: "text",
                value: ''
            },
            {
                label: "MSISDN",
                type: "text",
                value: ''
            },
            {
                label: 'Channels',
                model: 'select',
                channels: [
                    {
                        key: null,
                        value: 'all',
                        text: 'All Channels',
                        isClicked: false
                    },
                    {
                        key: 98,
                        value: 'dbmob',
                        text: 'DBMOB',
                        isClicked: false
                    },
                    {
                        key: 99,
                        value: 'dbweb',
                        text: 'DBWEB',
                        isClicked: false
                    }
                ]
            }
        ]
    },
    {
        pathname: '/topup-settlement-report',
        filters: [
            {
                label: "Start Date",
                type: "datetime-local",
                value: startDate
            },
            {
                label: 'End Date',
                type: 'datetime-local',
                value: endDate
            }
        ]
    }
]
