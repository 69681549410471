import React, { useState } from 'react';
import config from '../../config';
import { Button, Form, Select } from 'semantic-ui-react';
import Input from './Input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const Steps = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [customer, setCustomer] = useState();
    const [iban, setIban] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [description, setDescription] = useState('');
    const [mnos, setMNOS] = useState([]);
    const [bankBranch, setBankBranch] = useState([]);
    const [selectedMNOID, setSelectedMNOID] = useState();
    const [selectedBankBranch, setSelectedBankBranch] = useState();
    const [mnoValue, setMnoValue] = useState('Mobile Operator');
    const [bankBranchValue, setBankBranchValue] = useState('Bank Branch');

    const { step, nextStep, prevStep, closeModal } = props;

    const createCustomerHandler = (userData) => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": customer.id,
                "name": customer.name,
                "fathersName": customer.fathersName,
                "personalNumber": customer.personalNumber,
                "birthdate": customer.birthdate,
                "iban": iban,
                "customerBankId": customer.customerBankId,
                "msisdn": userData.msisdn,
                "address": userData.address,
                "city": userData.city,
                "email": userData.email,
                "mobileNetworkOperatorId": selectedMNOID,
                "bankBranchId": selectedBankBranch,
                "description": description
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success && mnoValue !== 'Mobile Operator' && setBankBranchValue !== 'Bank Branch') {
                nextStep();
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', data.value.birthDate);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('activationcode', data.value.activationCode);
                setLoading(false);
                window.open('/form/subform.html', "_blank");
                props.refreshHandler();
            } else {
                toast.error(data.description !== "" ? data.description : data.messages[0].text);
                setLoading(false);
            }
        })
    }

    const getMNOList = () => {
        fetch(`${config.baseURL}/admin/mobilenetworkoperator/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setMNOS(data.value.data);
        })
    };

    const getBankBranchList = () => {
        fetch(`${config.baseURL}/admin/bankbranch/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setBankBranch(data.value.data);
        })
    };

    const onSelectMNOHandler = (e, data) => {
        setMnoValue(data.value);
        mnos.map(mno => {
            if (mno.name === data.value) {
                return setSelectedMNOID(mno.indexId);
            }
        })
    }

    const onSelectBranchHandler = (e, data) => {
        setBankBranchValue(data.value)
        bankBranch.map(branch => {
            if (branch.displayName === data.value) {
                return setSelectedBankBranch(branch.indexId);
            }
        })
    }

    const getIbanHandler = () => {
        if (iban.length === 28 && iban.substring(0, 2) === 'AL') {
            setLoading(true);
            fetch(`${config.baseURL}/customers/sync-iban`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({
                    iban: iban
                })
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data.success) {
                    setError(null);
                    setCustomer(data.value);
                    setPhoneNumber(data.value.msisdn);
                    setEmail(data.value.email);
                    setAddress(data.value.address);
                    setCity(data.value.city);
                    nextStep();
                    setLoading(false);
                    getMNOList();
                    getBankBranchList();
                } else {
                    setError(data.messages[0].text);
                    setLoading(false)
                }
            })
        } else {
            setError('IBAN should start with AL and must be 28 characters long!');
        }
    };

    switch (step) {
        case 1:
            return (<div>
                <Form style={form}>
                    <Form.Field>
                        <Input label="Search by IBAN" type='text' name='IBAN' value={iban} placeholder='Search by IBAN' onChange={(e) => setIban(e.target.value)} />
                        {error ? <p className='iban-error'>{error}</p> : null}
                    </Form.Field>
                </Form>
                <div className="text-right">
                    <Button loading={loading} primary onClick={() => {
                        getIbanHandler();
                    }}>Next</Button>
                </div>
            </div>);
        case 2:
            return (<Formik
                initialValues={{ email: email, address: address, city: city, msisdn: phoneNumber, mno: '', branch: '' }}
                onSubmit={values => {
                    createCustomerHandler(values);
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Invalid email')
                        .required('This field is required.')
                        .trim(),
                    address: Yup.string()
                        .required('This field is required.')
                        .trim(),
                    city: Yup.string()
                        .required('This field is required.')
                        .trim(),
                    msisdn: Yup.string()
                        .required('This field is required.')
                        .trim()
                })}
            >
                {({ handleChange, handleBlur, errors, touched, values, handleSubmit, isValid }) => (
                    <React.Fragment>
                        <Form style={form}>
                            {customer ? (
                                <div>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Iban" type='text' name='iban' placeholder='Iban' value={iban} readOnly />
                                        <Form.Input fluid label="Father Name" type='text' name='fatherName' placeholder='Father Name' value={customer.fathersName} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Full Name" type='text' name='fullName' placeholder='Full Name' value={customer.name} readOnly />
                                        <Form.Input fluid label="Personal Number" type='text' name='personalNumber' placeholder='Personal Number' value={customer.personalNumber} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Customer Birth Date" type='text' name='birthdate' placeholder='Customer Birth Date' value={customer.birthdate ? moment(customer.birthdate).format('YYYY-MM-DD') : customer.birthdate} readOnly />
                                        <Form.Input fluid label="Customer Bank ID" type='text' name='customerBankID' placeholder='Customer Bank ID' value={customer.customerBankId} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.email && touched.email ? (
                                                <span className='formik-error-msg'>{errors.email}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.address && touched.address ? (
                                                <span className='formik-error-msg'>{errors.address}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="City" type='text' name='city' placeholder='City' value={values.city} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.city && touched.city ? (
                                                <span className='formik-error-msg'>{errors.city}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Customer Phone Number" type='text' name='msisdn' placeholder='Phone Number' value={values.msisdn} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.msisdn && touched.msisdn ? (
                                                <span className='formik-error-msg'>{errors.msisdn}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Mobile Operator</label>
                                            <Select placeholder={mnoValue} onChange={onSelectMNOHandler} onBlur={handleBlur} options={mnos.map(mno => {
                                                return { key: mno.id, value: mno.name, text: mno.name };
                                            })
                                            } style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                            {mnoValue === 'Mobile Operator' && touched.mno ? (
                                                <span className='formik-error-msg'>This field is required.</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bank Branch</label>
                                            <Select placeholder={bankBranchValue} onChange={onSelectBranchHandler} options={bankBranch.map(branch => {
                                                return { key: branch.id, value: branch.displayName, text: branch.displayName };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                            {bankBranchValue === 'Bank Branch' && touched.branch ? (
                                                <span className='formik-error-msg'>This field is required.</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Account Description" type='text' name='description' placeholder='Account Description' value={description} onChange={(e) => setDescription(e.target.value)} />
                                    </Form.Group>
                                </div>
                            ) : null}
                        </Form>
                        <div style={buttons}>
                            <Button primary onClick={prevStep}>Previous</Button>
                            <Button primary onClick={handleSubmit} loading={loading}>Save</Button>
                        </div>
                    </React.Fragment>
                )}
            </Formik>);
        case 3:
            return (<div>
                <h2 style={{ margin: '30px 0' }}>Customer Created Successfully</h2>
                <div style={buttons}>
                    <Button primary onClick={prevStep}>Previous</Button>
                    <Button primary onClick={closeModal}>Ok</Button>
                </div>
            </div>);
        default:
            return <ToastContainer />
    };
};

const form = {
    textAlign: 'left',
    margin: '15px 0'
}

const buttons = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 5px 0 10px'
}

export default Steps;